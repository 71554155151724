import React, { useState, useEffect } from 'react'
import { CurrencyDollarIcon, DocumentAddIcon, LocationMarkerIcon, MailIcon, MapIcon, SearchCircleIcon, SwitchVerticalIcon } from '@heroicons/react/solid'
import { getUsuarioLogado } from '../../services/usuario'
import { downloadArquivo } from '../../services/arquivo'
import API_URL from '../../utils/api'
import ResumoCLiente from '../../components/ResumoCliente'
import ResumoConsultaCliente from '../../components/ResumoConsultaCliente'
import ResumoMensalCliente from '../../components/ResumoMensalCliente'
import LoadingPage from '../../components/LoadingPage'
import { toast } from 'react-toastify';
import ResumoUploadArquivos from '../../components/ResumoUploadArquivos'

export default function Dashboard() {
    const [usuario, setUsuario] = useState()
    const [foto_url, setFotoUrl] = useState('')
    const [inicioPeriodo, setInicioPeriodo] = useState(null)
    const [fimPeriodo, setFimPeriodo] = useState(null)
    const [loading, setLoading] = useState(true)

    async function carregaUsuario() {
        const response = await getUsuarioLogado()

        if (response.sucesso) {
            setUsuario(response.data)
            if (response.data.arquivoFoto) carregaFotoUsuario(response.data.arquivoFoto.id)
            setLoading(false)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaFotoUsuario(id) {
        setLoading(true)
        const response = await downloadArquivo(id)

        if (response.sucesso) {
            const url = `${API_URL}/arquivo/download/${id}`
            setFotoUrl(url)
            setLoading(false)
        } else {
            // toast.error(response.mensagem, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
        }
    }

    useEffect(() => {
        carregaUsuario()

        if (inicioPeriodo && fimPeriodo) return;

        const diasAtras = new Date(new Date().getTime());
        diasAtras.setDate(new Date().getDate() - 10);
        const inicio = diasAtras
        const fim = new Date()
        setInicioPeriodo(inicio)
        setFimPeriodo(fim)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inicioPeriodo, fimPeriodo])

    function handleChangeInicioPeriodo(data) {
        if ((new Date(data)).getTime() > (new Date(fimPeriodo)).getTime()) {
            console.log('O início do peíodo deve ser igual ou anterior ao fim')
            setInicioPeriodo(new Date(fimPeriodo))
        } else {
            setInicioPeriodo(new Date(data))
        }
    }

    function handleChangeFimPeriodo(data) {
        if ((new Date(inicioPeriodo)).getTime() > (new Date(data)).getTime()) {
            console.log('O fim do peíodo deve ser igual ou posterior ao início')
            setFimPeriodo(new Date(inicioPeriodo))
        } else {
            setFimPeriodo(new Date(data))
        }
    }

    if (loading) return <LoadingPage />
    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-4">
                <div className="bg-white shadow rounded-lg">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                            <div className="flex-1 min-w-0">
                                {/* Profile */}
                                <div className="flex items-center">
                                    <img
                                        className="object-contain hidden h-16 w-16 rounded-full sm:block"
                                        src={foto_url || require('../../assets/images/user.png')}
                                        alt={"Foto usuario"}
                                    />
                                    <div>
                                        <div className="flex items-center truncate">
                                            <img
                                                className="object-contain h-16 w-16 rounded-full sm:hidden"
                                                src={foto_url || require('../../assets/images/user.png')}
                                                alt={"Foto usuario"}
                                            />
                                            <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 truncate">
                                                {usuario?.nome}
                                            </h1>
                                        </div>
                                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                            <dt className="sr-only">Company</dt>
                                            <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                                                <MailIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                                {usuario?.email}
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dl className="my-5 grid gap-5 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1">

                    <div className="relative bg-white h-fit pt-5 sm:pt-6 shadow rounded-lg overflow-hidden">
                        <dt className='px-4 sm:px-6'>
                            <div className="absolute bg-blue-800 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Clientes por Revenda</p>
                        </dt>
                        <dd className="mt-12 flex items-baseline">
                            <ResumoCLiente tipoResumo="Revenda" />
                        </dd>
                    </div>

                    <div className="relative bg-white h-fit pt-5 sm:pt-6 shadow rounded-lg overflow-hidden">
                        <dt className='px-4 sm:px-6'>
                            <div className="absolute bg-blue-800 rounded-md p-3">
                                <MapIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Clientes por UF</p>
                        </dt>
                        <dd className="mt-12 flex items-baseline">
                            <ResumoCLiente tipoResumo="Uf" />
                        </dd>
                    </div>

                    <div className="relative bg-white h-fit pt-5 sm:pt-6 shadow rounded-lg overflow-hidden">
                        <dt className='px-4 sm:px-6'>
                            <div className="absolute bg-blue-800 rounded-md p-3">
                                <LocationMarkerIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Clientes por Município</p>
                        </dt>
                        <dd className="mt-12 flex items-baseline">
                            <ResumoCLiente tipoResumo="Municipio" />
                        </dd>
                    </div>

                    <div className="xl:col-span-3 lg:col-span-2 relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-800 rounded-md p-3">
                                <SwitchVerticalIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Contratações e Cancelamentos</p>
                        </dt>
                        <dd className="mt-12 flex items-baseline">
                            {/* <Echarts /> */}
                            <ResumoMensalCliente />
                        </dd>
                    </div>

                    <dl className="grid gap-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 col-span-full">
                        <div className="col-span-2 relative pb-6 sm:pt-3 overflow-hidden">
                            <p className="text-sm font-medium text-gray-500 truncate">Período</p>
                            <dd className="mt-4 flex items-baseline ">
                                <div className="flex flex-wrap items-center w-full">
                                    <input
                                        onChange={e => handleChangeInicioPeriodo(e.target.value)}
                                        type="date"
                                        value={(new Date(inicioPeriodo)).toISOString().split('T')[0]}
                                        className=" px-4 py-2 border-2 border-gray-200 form-control rounded-md sm:w-auto w-full mr-0 sm:mr-4 mt-4 sm:mt-0" />
                                    <input
                                        onChange={e => handleChangeFimPeriodo(e.target.value)}
                                        type="date"
                                        value={(new Date(fimPeriodo)).toISOString().split('T')[0]}
                                        className=" px-4 py-2 border-2 border-gray-200 form-control rounded-md sm:w-auto w-full mr-0 sm:mr-4 mt-4 sm:mt-0" />
                                </div>
                            </dd>
                        </div>
                    </dl>

                    <div className="xl:col-span-3 lg:col-span-2 relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-800 rounded-md p-3">
                                <SearchCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Consultas de Licença</p>
                        </dt>
                        <dd className="mt-12 flex items-baseline">
                            {/* <Echarts /> */}
                            <ResumoConsultaCliente inicioPeriodo={inicioPeriodo} fimPeriodo={fimPeriodo} />
                        </dd>
                    </div>



                    <div className="xl:col-span-3 lg:col-span-2 relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-800 rounded-md p-3">
                                <DocumentAddIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Upload Arquivos de Clientes</p>
                        </dt>
                        <dd className="mt-12 flex items-baseline">
                            {/* <Echarts /> */}
                            <ResumoUploadArquivos inicioPeriodo={inicioPeriodo} fimPeriodo={fimPeriodo} />
                        </dd>
                    </div>
                </dl>
            </div>
        </>
    )
}
